<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-account-star-outline
      </v-icon>
      <b>{{ 'title.listUsers'.translate() }}</b>
    </HeaderCard>

    <v-card-text style="padding: 0;">
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          grid-list-xl
          class="mx-3"
        >
          <v-layout wrap>
            <v-flex
              xs12
              sm6
              md8
            >
              <FilterText
                label="filter.userArg"
                v-model="filters.nome"
              />
            </v-flex>

            <v-flex
              xs12
              sm3
              md2
            >
              <FilterGrau
                label="Grau"
                v-model="filters.grau"
              />
            </v-flex>

            <v-flex
              xs12
              sm3
              md2
            >
              <FilterProfile
                label="Perfil"
                v-model="filters.profile"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>

      <v-data-table
        :headers="headers"
        :items="rows"
        :loading="isLoading"

        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"

        disable-pagination
        hide-default-footer

        class="elevation-0"
        item-key="id"
      >
        <template #item="{ item, index }">
          <UserSearchItem
            :id="item.id"
            :grau="item.grau"
            :name="item.name"
            :email="item.email"
            :phone="item.phone"
            :odd="index % 2"
            v-model="item.profiles"
          />
        </template>

        <template #footer>
          <v-divider class="my-2" />

          <v-pagination
            v-model="page"
            :length="Math.ceil(count / 12)"
            :total-visible="7"
            v-if="Math.ceil(count / 12) > 1"
            @input="$vuetify.goTo(0, { duration: 850, offset: 0, easing: 'easeInOutCubic' })"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import FilterText from '@/components/filters/FilterText'
import FilterGrau from '@/components/filters/FilterGrau'
import FilterProfile from '@/components/filters/FilterProfile'
import HeaderCard from '@/components/HeaderCard'
import UserSearchItem from './UserSearchItem'
import { mapState } from 'vuex'

import {
  STATUS_LOADING,
  ROUTE_USERS_SEARCH,
  ACTION_USERS_SEARCH
} from '@/constants'

const hasQryVal = (qry, val) => qry[val] !== undefined && String(qry[val]).trim()

export default {
  components: {
    FilterText,
    FilterGrau,
    FilterProfile,
    HeaderCard,
    UserSearchItem
  },

  data () {
    const qry = { ...this.$route.query }
    const page = isNaN(qry.page) ? 1 : Number(qry.page)
    const sortBy = hasQryVal(qry, 'sortBy') ? qry.sortBy : 'nome'
    const orderDesc = String(qry.order || '').trim() === 'DESC'

    const filters = {
      nome: hasQryVal(qry, 'nome') ? qry.nome : undefined
    }

    const options = {
      sortBy: [sortBy],
      sortDesc: [orderDesc],
      itemsPerPage: 12
    }

    return {
      page,
      options,
      filters
    }
  },

  computed: {
    ...mapState({
      rows: ({ searchUsers }) => searchUsers.rows,
      count: ({ searchUsers }) => searchUsers.count,
      isLoading: ({ searchUsers }) => searchUsers.status === STATUS_LOADING
    }),

    headers () {
      return [
        { text: 'Grau', align: 'center', sortable: true, value: 'grau' },
        { text: 'Nome', align: 'start', sortable: true, value: 'name' },
        { text: 'E-mail', align: 'start', sortable: true, value: 'email' },
        { text: 'Celular', align: 'start', sortable: false, value: 'phone' },
        { text: 'Perfil', align: 'center', sortable: false, value: 'perfil' }
      ]
    }
  },

  methods: {
    searchUsers () {
      const query = {
        page: this.page,
        order: this.options.sortDesc[0] !== false ? 'DESC' : 'ASC',
        sortBy: this.options.sortBy.length ? this.options.sortBy[0] : 'nome'
      }

      const fltrs = { ...this.filters }
      if (hasQryVal(fltrs, 'nome')) query.nome = fltrs.nome
      if (hasQryVal(fltrs, 'grau')) query.grau = fltrs.grau
      if (hasQryVal(fltrs, 'profile')) query.profile = fltrs.profile

      this.$store.dispatch(ACTION_USERS_SEARCH, query)
      this.$router.push({ name: ROUTE_USERS_SEARCH, query })
    }
  },

  watch: {
    page () {
      this.searchUsers()
    },

    filters: {
      deep: true,
      handler () {
        this.page = 1
        this.searchUsers()
      }
    },

    options: {
      deep: true,
      handler () {
        this.searchUsers()
      }
    }
  }
}
</script>
