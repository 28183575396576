<template>
  <tr :class="odd ? 'odd' : 'even'">
    <td class="text-center">
      {{ grau }}
    </td>

    <td class="text-left">
      {{ name }}
    </td>

    <td class="text-left">
      {{ email }}
    </td>

    <td class="text-left">
      {{ phone }}
    </td>

    <td
      class="text-center"
      style="max-width: 127px"
    >
      <FieldUserProfile
        :user="id"
        v-model="profiles"
      />
    </td>
  </tr>
</template>

<script>
import FieldUserProfile from '@/components/fields/FieldUserProfile'

export default {
  components: {
    FieldUserProfile
  },

  props: [
    'id',
    'grau',
    'name',
    'email',
    'phone',
    'value',
    'odd'
  ],

  data () {
    return {
      profiles: Array.isArray(this.value) ? this.value : []
    }
  },

  methods: {
    isEqual: (source, target) => JSON.stringify(source) === JSON.stringify(target)
  },

  watch: {
    profiles (profiles) {
      if (this.isEqual(this.value, profiles)) return
      this.$emit('input', profiles)
    },

    value (value) {
      if (this.isEqual(value, this.profiles)) return
      this.profiles = Array.isArray(value) ? value : []
    }
  }
}
</script>

<style scoped>
  tr.odd>td {
    background-color: #0094d90f
  }
</style>
