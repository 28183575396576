<template>
  <v-select
    dense
    outlined
    hide-details
    single-line
    :items="options"
    v-model="profile"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'

import {
  REVISOR_CODE,
  DIRIGENTE_CODE,
  SUPERVISOR_CODE,
  PESQUISADOR_CODE,
  ADMINISTRADOR_CODE
} from '@/constants'

export default {
  props: {
    value: { default: [] },
    user: {},
    icon: {},
    label: { default: '' },
    outlined: { default: false },
    readonly: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return this.prepareProfiles(this.value)
  },

  methods: {
    isEqual: (source, target) => JSON.stringify(source) === JSON.stringify(target),

    prepareProfiles (value) {
      const profiles = Array.isArray(value) ? value : []

      let profile = null
      if (profiles.includes(ADMINISTRADOR_CODE) && !profile) profile = ADMINISTRADOR_CODE
      if (profiles.includes(SUPERVISOR_CODE) && !profile) profile = SUPERVISOR_CODE
      if (profiles.includes(REVISOR_CODE) && !profile) profile = REVISOR_CODE
      if (profiles.includes(DIRIGENTE_CODE) && !profile) profile = DIRIGENTE_CODE
      if (!profile) profile = PESQUISADOR_CODE

      const options = [
        { value: ADMINISTRADOR_CODE, text: 'Administrador' },
        { value: SUPERVISOR_CODE, text: 'Supervisor' },
        { value: REVISOR_CODE, text: 'Revisor' }
      ]

      options.push(
        profiles.includes(DIRIGENTE_CODE)
          ? { value: DIRIGENTE_CODE, text: 'Dirigente' }
          : { value: PESQUISADOR_CODE, text: 'Pesquisador' }
      )

      return { profile, profiles, options }
    },

    ignoreRequest: (profile) => !profile || [DIRIGENTE_CODE, PESQUISADOR_CODE].includes(profile),

    async addProfile (profile) {
      if (this.ignoreRequest(profile)) return
      return requests.setUserProfile(this.user, profile)
    },

    async removeProfile (profile) {
      if (this.ignoreRequest(profile)) return
      return requests.removeUserProfile(this.user, profile)
    }
  },

  watch: {
    profile (newProfile, oldProfile) {
      Promise.all([
        this.addProfile(newProfile),
        this.removeProfile(oldProfile)
      ]).then(() => {
        const profiles = [...this.profiles]
        if (newProfile && !profiles.includes(newProfile)) profiles.push(newProfile)
        if (oldProfile && profiles.includes(oldProfile)) profiles.splice(profiles.indexOf(oldProfile), 1)
        this.profiles = profiles
      })
    },

    profiles (profiles) {
      if (this.isEqual(this.value, profiles)) return
      this.$emit('input', profiles)
    },

    value (value) {
      if (this.isEqual(value, this.profiles)) return
      Object.assign(this, this.prepareProfiles(value))
    }
  }
}
</script>
